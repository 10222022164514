import React, {Component} from 'react';
import {T} from './Utils';

class DeviceFilter extends Component {

    constructor(props) {
        super(props)
        this.state = {
            filterText: ''
        };
    }

    handleFilterChange = (e) => {
        e.preventDefault()
        this.setState({filterText: e.target.value})
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            this.setDeviceFilter(e)
        }
    }

    setDeviceFilter = (e) => {
        e.preventDefault()
        this.props.setDeviceFilter(this.state.filterText)
    }

    render() {
        return (
            <p>
                <form className="p-search-box">
                <input className="p-search-box__input" type="search" name="filterText" placeholder={this.props.placeholderText}
                    onKeyPress={this.handleKeyPress}
                    onChange={this.handleFilterChange} />
                <button type="submit" onClick={this.setDeviceFilter} className="p-search-box__button" alt="search"><i className="p-icon--search" /></button>
                </form>
            </p>
        )
    }
}

export default DeviceFilter;